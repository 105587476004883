import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

export const usePageview = (): void => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, []);
};
