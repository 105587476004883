import React from "react";
import {Navbar as ReactstrapNavbar, NavbarBrand} from "reactstrap";
import styled from "styled-components";

const NavbarWrapper = styled.div`
    width: 100%;

    * {
        padding-left: 0 !important;
    }
`;

const PrimarySpan = styled.span`
    color: ${({theme}) => theme.primary};
`;

const SecondarySpan = styled.span`
    color: ${({theme}) => theme.secondary};
`;

const Navbar = (): JSX.Element => {
    return (
        <NavbarWrapper>
            <ReactstrapNavbar expand="md">
                <NavbarBrand href="/">
                    <PrimarySpan>Not</PrimarySpan>
                    <SecondarySpan>FTs</SecondarySpan>
                </NavbarBrand>
            </ReactstrapNavbar>
        </NavbarWrapper>
    );
};

export default Navbar;
