import React from "react";
import styled from "styled-components";

type CarouselDropProps = {
    img: string;
};

const CarouselDrop = ({img}: CarouselDropProps): JSX.Element => (
    <CarouselDropWrapper>
        <CarouselDropImg src={img} />
        <Traits>
            <TraitsTitle>Traits</TraitsTitle>
            real ape: no
            <br />
            fake ape: yes
            <br />
            disguised: yes
            <br />
        </Traits>
    </CarouselDropWrapper>
);

const CarouselDropWrapper = styled.div`
    width: 100%;
    padding: 4px 20px;
`;

const CarouselDropImg = styled.img`
    width: 100%;
    height: auto;
    border-radius: 5%;
    border: 2px solid #000;
    box-shadow: 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000, 4px 4px 0 #000;
`;

const Traits = styled.div`
    font-weight: 400;
`;

const TraitsTitle = styled.div`
    color: ${({theme}) => theme.secondary};
    font-weight: 800;
    margin-bottom: 4px;
    margin-top: 16px;
`;

export default CarouselDrop;
