import React from "react";
import ReactGA from "react-ga4";
import styled from "styled-components";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Carousel} from "@trendyol-js/react-carousel";
import {Col, Row} from "reactstrap";

import Navbar from "./Navbar";
import CarouselDrop from "./CarouselDrop";
import Tilt from "./Tilt";
import {usePageview} from "./utils";

import coverImg from "./img/cover.png";
import leftArrowImg from "./img/leftarrow.svg";
import rightArrowImg from "./img/rightarrow.svg";
import ape1 from "./img/modified_ape_69.png";
import ape2 from "./img/modified_ape_94.png";
import ape3 from "./img/modified_ape_142.png";
import ape4 from "./img/modified_ape_180.png";
import ape5 from "./img/modified_ape_369.png";
import rainbow from "./img/rainbow.gif";

ReactGA.initialize("G-ZMG7W5EDRV");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tiltProps: any = {
    perspective: 600,
    glareEnable: true,
    glareMaxOpacity: 0.4,
    glarePosition: "all",
};

const App = (): JSX.Element => {
    const Content = () => {
        usePageview();
        return (
            <>
                <HeaderBg>
                    <Main>
                        <HeaderContent>
                            <Navbar />
                            <div>
                                <h1>
                                    <span className="rainbow">Definitely not </span>
                                    <span>bored apes</span>
                                </h1>
                                <p>The worst-disguised crypto scam ever 💸</p>
                            </div>
                        </HeaderContent>
                    </Main>
                </HeaderBg>
                <Main>
                    <Coverimage src={coverImg} />
                    <Quote>
                        <h2>&ldquo;When I was a child, JPEGs were free&rdquo;</h2>
                        <p>- Albert Einstein</p>
                    </Quote>
                    <Button>Buy on OpenSea</Button>
                </Main>
                <CarouselBg>
                    <Main>
                        <h2>Current drop 🙈</h2>
                        <br />
                        <br />
                        <Carousel
                            show={1}
                            slide={1}
                            swiping={true}
                            transition={0.3}
                            useArrowKeys={true}
                            rightArrow={<Arrow backgroundImg={rightArrowImg} />}
                            leftArrow={<Arrow backgroundImg={leftArrowImg} />}
                        >
                            <CarouselDrop img={ape2} />
                            <CarouselDrop img={ape3} />
                            <CarouselDrop img={ape4} />
                            <CarouselDrop img={ape5} />
                            <CarouselDrop img={ape1} />
                        </Carousel>
                        <Quote>
                            <h2>It&apos;s not like you can afford the real ones anyway.</h2>
                        </Quote>
                    </Main>
                </CarouselBg>
                <UpcomingBg>
                    <Main>
                        <h2>⚠️ Upcoming drop ⚠️</h2>
                        <Tilt {...tiltProps}>
                            <DictatorImage src={process.env.PUBLIC_URL + "/img/kim_jong_ape.png"} />
                        </Tilt>
                        <Quote style={{marginTop: "16px"}}>
                            <p style={{textAlign: "center"}}>North Korean Ape</p>
                            <h2 style={{textAlign: "center"}}>Dictator Ape Megayacht Club</h2>
                        </Quote>
                    </Main>
                </UpcomingBg>
                <TestimonialsBg>
                    <MainWide>
                        <h2>Testimonials 🙊</h2>
                        <Row>
                            <Col>
                                <Quote style={{marginTop: "16px"}}>
                                    <h2>
                                        &quot;Do they have a talented artist? No. Do they have a novel smart contract?
                                        Also no.&quot;
                                    </h2>
                                    <p>- Vitalik Capuchin</p>
                                </Quote>
                            </Col>
                            <Col>
                                <Quote style={{marginTop: "16px"}}>
                                    <h2>&quot;I just purchased this company for 50 million dollars.&quot;</h2>
                                    <p>- Marcaque Andreesen</p>
                                </Quote>
                            </Col>
                        </Row>
                    </MainWide>
                </TestimonialsBg>
                <FooterBg>
                    <Main>
                        <p>
                            Copyright NotFTs (2022)
                            <br />
                            Is this even copyrightable?
                        </p>
                    </Main>
                </FooterBg>
            </>
        );
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Content />} />
            </Routes>
        </BrowserRouter>
    );
};

const Main = styled.div`
    align-items: center;
    margin: auto;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 620px;
    padding: 0 10px;

    h2 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 16px;
        margin-top: 24px;
    }
`;

const MainWide = styled(Main)`
    max-width: 1000px;
`;

const Coverimage = styled.img`
    height: auto;
    max-width: 100%;
    margin: 69px auto auto auto;
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 240px;
    padding: 20px 0 8px 0;
    text-align: left;

    .rainbow {
        filter: brightness(85%);
        background-image: url(${rainbow});
        background-size: cover;
        white-space: nowrap;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h1 {
        font-size: 44px;
        font-weight: 700;

        @media only screen and (max-width: 500px) {
            span {
                display: block;
            }
        }

        @media only screen and (max-width: 380px) {
            font-size: 40px;
        }
    }

    p {
        font-size: 16px;
        color: ${({theme}) => theme.dark2};
    }
`;

const HeaderBg = styled.div`
    background: ${({theme}) => theme.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0 0;
`;

const CarouselBg = styled.div`
    border-top-width: thick;
    border-top-style: solid;
    border-bottom-width: thick;
    border-bottom-style: solid;
    border-image: url(${rainbow});
    border-image-slice: 1;
    background: ${({theme}) => theme.light2};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 69px;
    padding: 40px 10px;
`;

const UpcomingBg = styled.div`
    padding: 40px 10px;
`;

const TestimonialsBg = styled.div`
    border-top-width: thick;
    border-top-style: solid;
    border-image: url(${rainbow});
    border-image-slice: 1;
    background: ${({theme}) => theme.light2};
    padding: 40px 10px;
`;

const FooterBg = styled.div`
    background: linear-gradient(to right, ${({theme}) => theme.yellow} 5%, ${({theme}) => theme.accent});
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
        margin-bottom: 0;
    }
`;

const Quote = styled.div`
    text-align: left;
    margin: 69px auto 32px auto;
    width: fit-content;
    max-width: 400px;

    h2 {
        text-align: left;
        color: ${({theme}) => theme.primary};
        font-size: 28px;
        font-weight: 700;
    }

    p {
        color: ${({theme}) => theme.secondary};
        font-size: 16px;
    }
`;

const Button = styled.div`
    text-align: center;
    border-radius: 2px;
    height: 32px;
    width: fit-content;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 20px;
    margin: auto;
    cursor: pointer;
    border: 2px solid #000;
    box-shadow: 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000;
    transform: translate(-2px, -2px);
    transition: all 0.1s ease;

    :hover {
        background-color: ${({theme}) => theme.light1};
    }

    :active {
        box-shadow: 1px 1px 0 #000;
        transform: translate(0px, 0px);
    }
`;

const Arrow = styled.div<{backgroundImg: string}>`
    background-image: url(${({backgroundImg}) => backgroundImg});
    width: 40px;
    height: 40px;
    bottom: 50%;
    margin-bottom: -33px;
    background-size: 60%;
    border: 1px solid #000;
    background-color: #fff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 2px 2px 0 #000;
    transform: translate(-1px, -1px);
    transition: all 0.1s ease;
    cursor: pointer;
    display: flex;
    margin: 0 10px;

    :active {
        box-shadow: 1px 1px 0 #000;
        transform: translate(0px, 0px);
    }
`;

const DictatorImage = styled.img`
    border-radius: 16px;
    height: auto;
    width: 360px;
    max-width: 100%;
    margin: 20px auto 8px auto;
    box-shadow: 0px 0px 12px ${({theme}) => theme.gold};
`;

export default App;
