/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import ReactParallaxTilt from "react-parallax-tilt";

interface Tilted extends React.Component {}

const Tilt = ReactParallaxTilt as any as {
    new (): Tilted;
};

export default Tilt;
