export default {
    /* Primary Palette */
    primary: "#DD1155",
    secondary: "#28AFB0",
    accent: "#b4eaf7",
    accent2: "#b6f8e9",
    yellow: "#e9e9b7",
    gold: "#FFDA87",

    /* Red */
    red: "#f88ba8",
    darkRed: "#ea4672",
    error: "#cc1748",
    darkError: "#bb1e1e",

    /* green */
    success: "#00FF7F",

    /* Greys */
    white: "#ffffff",
    dark1: "#060606",
    dark2: "#505f79",
    dark3: "#97a0af",
    light1: "#f8f8f8",
    light2: "#f3f3f3",
    light3: "#e1e1e1",
    light4: "#c6c6c6",
    black: "#000000",

    transparent: "rgba(0,0,0,0)",

    breakpoints: {
        zero: 0,
        sm: 480,
        md: 768,
        lg: 1024,
        xl: 1200,
    },
};
